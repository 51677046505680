import * as React from "react";

const LimePaint = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "30px",
      }}
    >
      <h1
        style={{ textAlign: "center", fontSize: "36px", marginBottom: "30px" }}
      >
        Lime paint
      </h1>
      <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
        Lime paint is a type of paint that is made from hydrated lime, which is
        derived from limestone. Lime paint is known for its breathability, which
        means it allows moisture to pass through the paint film, allowing the
        walls to "breathe". This is particularly beneficial in older buildings,
        where moisture can be an issue. Lime paint is also a more
        environmentally friendly option compared to traditional paints, as it is
        made from natural materials and does not contain any harmful chemicals.
        It also has a very low VOC (volatile organic compound) content, which
        means it does not release harmful pollutants into the air. In terms of
        finish, lime paint can be used to create a range of finishes, from
        smooth and polished to rough and textured. It can also be pigmented to
        create a wide range of colors. Lime paint is a traditional paint that is
        used in historic buildings, and in contemporary buildings where the
        natural look of the building is desired. It is important to note that
        Lime paint takes longer to dry and cure compare to regular paint and
        require more maintenance, Lime paint also needs a skilled applicator to
        apply and it is more expensive than regular paint. Overall, lime paint
        is a beautiful, breathable, and environmentally friendly option for
        painting walls, but it may require more effort and cost to maintain and
        apply.
      </p>
    </div>
  );
};

export default LimePaint;
